import React, {Component} from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import HeaderView from "../HeaderView";

class ActionRequestPromptView extends Component {
    render() {
        const {primaryTitle, secondaryTitle, button, colorGuidance} = this.props;

        return (
            <Column spacingInset="medium" heights="fill" height="100%">
                <Column alignmentHorizontal="center" alignmentVertical="top">
                    {
                        <HeaderView primaryTitle={primaryTitle} secondaryTitle={secondaryTitle}/>
                    }
                    {colorGuidance &&
                        <Column alignmentHorizontal="center" spacing="none" width="100%">
                            <Box type="outline" spacingInset="450" backgroundColor={colorGuidance.color}
                                 minWidth={"80%"}></Box>
                        </Column>
                    }
                </Column>
                <Column alignmentVertical="bottom">
                    <Button onClick={button.onClick} size={button.size ? button.size : "large"} type={button.type ? button.type : "secondary"}>
                        {button.title}
                    </Button>
                </Column>
            </Column>
        )
    }
}

ActionRequestPromptView.propTypes = {
    primaryTitle: HeaderView.propTypes.primaryTitle,
    secondaryTitle: HeaderView.propTypes.secondaryTitle,
    colorGuidance: PropTypes.shape({
        color: PropTypes.string
    }),
    button: PropTypes.shape({
        title: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    })
}

export default injectIntl(ActionRequestPromptView);